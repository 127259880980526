<!--
    Dalla documentazione: https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/templates/#toc-value-template

    Value Template
    To customize the content of the element which holds the selected text, use the value template. To define a value template, nest an <ng-template> tag with a kendoDropDownListValueTemplate directive inside a <kendo-dropdownlist> tag.
-->

<ng-template #valueTemplate
    let-dataItem>
    <ng-container *ngIf="dataItem != null">
        <div class="value-row">
            <span class="value-label"
                container="body"
                tooltipClass="nr-tooltip"
                [placement]="tooltipPlacement"
                [ngbTooltip]="dataItem.label"
                [class.inactive]="dataItem.inactive"
                [class.deleted]="dataItem.deleted">{{dataItem.label}}</span>
        
            <span *ngIf="dataItem.qualifier"
                class="value-qualifier">
                <ng-container *ngIf="qualifierTranslatePrefix; else plainQualifier">
                    {{ (qualifierTranslatePrefix+dataItem.qualifier)|translate }}
                </ng-container>
                <ng-template #plainQualifier>{{ dataItem.qualifier }}</ng-template>
            </span>
        
            <span *ngIf="showIdOnItem"
                class="value-id">{{ dataItem.id }}</span>
        
        </div>
    </ng-container>
</ng-template>

<!--
    Dalla documentazione: https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/templates/#toc-item-template

    Item Template
    To customize the content of the suggestion list items, use the item template. To define an item template, nest an <ng-template> tag with the kendoDropDownListItemTemplate directive inside a <kendo-dropdownlist> tag.
-->

<ng-template #itemTemplate
    let-dataItem>
    <ng-container *ngIf="dataItem != null">
        <div class="item-row">
            <span class="item-label"
                [class.inactive]="dataItem.inactive"
                [class.deleted]="dataItem.deleted">{{dataItem.label}}</span>

            <span class="item-qualifier"
                *ngIf="dataItem.qualifier">
                <ng-container *ngIf="qualifierTranslatePrefix; else plainQualifier">
                    {{ (qualifierTranslatePrefix+dataItem.qualifier)|translate }}
                </ng-container>
                <ng-template #plainQualifier>{{ dataItem.qualifier }}</ng-template>
            </span>

            <span *ngIf="showIdOnOptions"
                class="item-id">{{ dataItem.id }}</span>

            <div *ngIf="popOverData"
                class="item-popOverTrigger">
                <i class="fas fa-info-circle"
                    container="body"
                    tooltipClass="nr-tooltip"
                    [placement]="tooltipPlacement"
                    [ngbTooltip]="dataItem.label"></i>
            </div>
        </div>
    </ng-container>
</ng-template>

<div class="dropdown-container">
    <kendo-dropdownlist [data]="itemsSource.items$ | async"
                        class="position-abs"
                        (focus)="onListFocus()"
                        [style.visibility]="showList && value != null ? 'visible' : 'hidden'"
                        [style.display]="showList && value != null ? 'flex' : 'none'"
                        [value]="value"
                        [valueField]="this.valueField"
                        [textField]="this.textField"
                        [valuePrimitive]="false"
                        [size]="this.size"
                        [attr.required]="this.required ?? null"
                        [disabled]="disabled">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
        
            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: dataItem }"></ng-container>
        </ng-template>

        <ng-template kendoDropDownListItemTemplate
            let-dataItem>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: dataItem }"></ng-container>
        </ng-template>
    </kendo-dropdownlist>

    <kendo-combobox [style.visibility]="!showList || value == null ? 'visible' : 'hidden'"
                    class="position-abs"
                    (blur)="onComboBlur()"
                    [data]="itemsSource.items$ | async"
                    [loading]="itemsSource.searching$ | async"
                    [ngModel]="value"
                    (ngModelChange)="setValue($event)"
                    [valueField]="this.valueField"
                    [textField]="this.textField"
                    [valuePrimitive]="false"
                    [filterable]="true"
                    [size]="this.size"
                    (filterChange)="onFilterChanged($event)"
                    (valueChange)="onSelectionChanged($event)"
                    [placeholder]="placeholder"
                    [attr.required]="this.required ?? null"
                    [disabled]="disabled">

        <ng-template kendoComboBoxNoDataTemplate>
            <ng-container *ngIf="filterText == null || filterText == ''; else noData">
                <span>{{ 'NR-DROPDOWN.TYPE-TO-SEARCH' | translate }}</span>
            </ng-container>
            <ng-template #noData>
                <span>{{ 'NR-DROPDOWN.NOT-FOUND' | translate }}</span>
            </ng-template>
        </ng-template>

        <ng-template kendoComboBoxItemTemplate
            let-dataItem>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: dataItem }"></ng-container>
        </ng-template>

    </kendo-combobox>
</div>

<!-- LINKOLOGY -->
<!-- https://www.telerik.com/kendo-angular-ui/components/dropdowns/  -->