import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

import { NrMultiSelectComponent } from './nr-multiselect/nrmultiselect.component';
import { NrMultiSelectAllComponent } from './nr-multiselect/nrmultiselectall.component';
import { NrDropdownComponent } from './nr-dropdown/nrdropdown.component';
import { NrTripExistsValidatorDirective } from './validators/nr-trip-exists/nr-trip-exists.directive';
import { NrFloatComponent } from './nr-float/nrfloat.component';
import { NrMinValueDirective } from './validators/nr-min-value/nr-min-value.directive';
import { NrEmailDirective } from './validators/nr-email/nr-email.directive';
import { NrMaxValueDirective } from './validators/nr-max-value/nr-max-value.directive';
import { TableSourceSortDirective } from './nr-table/table-source-sort.directive';
import { RtfmService } from './nr-rtfm/rtfm.service';
import { SanitizerUrlPipe } from './pipes/sanitize-url.pipe';
import { AfterValueChangedDirective } from './miscellaneous/directives/after-value-changed.directive';
import { FileSizePipe } from './pipes/file-size-pipe';

import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';

export { DateTimeUtilities } from './utilities/date-time-utilities';
export { StringUtilities } from './utilities/string-utilities';

const KENDO = [TextBoxModule, InputsModule];

@NgModule({
	declarations: [
		TableSourceSortDirective,
		NrMultiSelectComponent,
		NrMultiSelectAllComponent,
		NrDropdownComponent,
		NrTripExistsValidatorDirective,
		NrMinValueDirective,
		NrMaxValueDirective,
		NrFloatComponent,
		NrEmailDirective,
		SanitizerUrlPipe,
		AfterValueChangedDirective,
		FileSizePipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		NgSelectModule,
		NgbTooltipModule,
		TranslateModule.forChild(),
		NgOptionHighlightModule,
		DropDownsModule, ...KENDO],
	exports: [
		TableSourceSortDirective,
		NrMultiSelectComponent,
		NrMultiSelectAllComponent,
		NrDropdownComponent,
		NrTripExistsValidatorDirective,
		NrMinValueDirective,
		NrMaxValueDirective,
		NrFloatComponent,
		NgbTooltipModule,
		NrEmailDirective,
		SanitizerUrlPipe,
		AfterValueChangedDirective,
		FileSizePipe, ...KENDO
	],
	providers: [RtfmService],
})
export class NrCommonModule {}
