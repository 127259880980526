import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {BaseDataService, ConfigService, UserService} from 'nr-common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsModule} from './components/components.module';
import {ApiService} from './services/api.service';
import {BannerService} from './services/banner.service';
import {DestinationService} from './services/destination.service';
import {IdeaService} from './services/idea.service';
import {TripService} from './services/trip.service';
import {WebsiteService} from './services/website.service';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {
	deLocale,
	enGbLocale,
	esLocale,
	frLocale,
	itLocale,
    nlBeLocale
} from 'ngx-bootstrap/locale';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeNl from '@angular/common/locales/nl';
import * as moment from 'moment';
import { NrMapsModule } from 'nr-maps';
import { Observable } from 'rxjs';

//import { WidgetService } from './services/widget.service';

export function userFactory(
	config: ConfigService,
	user: UserService,
	website: WebsiteService
) {
	registerLocaleData(localeIt, 'it');
	registerLocaleData(localeFr, 'fr');
	registerLocaleData(localeEn, 'en');
	registerLocaleData(localeDe, 'de');
	registerLocaleData(localeEs, 'es');
	registerLocaleData(localeNl, 'nl');
	defineLocale('it', itLocale);
	defineLocale('de', deLocale);
	defineLocale('es', esLocale);
	defineLocale('fr', frLocale);
	defineLocale('en', enGbLocale);
	defineLocale('nl', nlBeLocale);
	
    moment.locale('it');
	
    config.from(environment);
	
    const p1 = user.init();
    const p2 = website.init();
	return () => Promise.all([p1, p2]);
}

export class CustomTranslateLoader implements TranslateLoader  {
	public contentHeader = new HttpHeaders({"Content-Type": "application/json","Access-Control-Allow-Origin":"*",});
	
	public constructor(private http: HttpClient) {}

	public getTranslation(langCode: string): Observable<any>{
		var apiAddress = `${environment.servicesEndpointRoot}${environment.i18nFiles}b2c/${langCode}`;
		return this.http.post(apiAddress, {}, { headers: this.contentHeader, withCredentials: true });
	}
}

//? Aggiunta per risolvere gli errori con il translate.instant
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return () => new Promise<any>((resolve: any) => {
		const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
		locationInitialized.then(() => {
			const langToSet = translate.currentLang;
			translate.use(langToSet).subscribe(() => {
			}, err => {
				console.error(`Problem with '${langToSet}' language initialization: ${err}`);
			}, () => {
				resolve(null);
			});
		});
	});
}

export function TranslateMessageFormatCompilerFactory() {
	return new TranslateMessageFormatCompiler();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		NgbDropdownModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomTranslateLoader,
				deps: [HttpClient],
			},
			compiler: {
				provide: TranslateCompiler,
				useFactory: TranslateMessageFormatCompilerFactory,
			},
		}),
		BrowserAnimationsModule,
		NrMapsModule.forRoot({
			apiKey: environment.googleMapsApiKey,
		}),
		ComponentsModule,
	],
	providers: [
		ApiService,
		BannerService,
		BaseDataService,
		DestinationService,
		IdeaService,
		Title,
		WebsiteService,
        //WidgetService,
		TripService,
		{
			provide: APP_INITIALIZER,
			useFactory: userFactory,
			// useFactory: (
			// 	configService: ConfigService,
			// 	userService: UserService,
			// 	website: WebsiteService
			// ) => {
			// 	configService.from(environment);
			// 	const p1 = userService.init();
			// 	const p2 = website.init();
			// 	return () => Promise.all([p1, p2]);
			// },
			deps: [ConfigService, UserService, WebsiteService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{
			provide: MESSAGE_FORMAT_CONFIG,
			useValue: {locales: ['it', 'en', 'fr', 'de', 'es']},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}

/**
	https://github.com/ngx-translate/core#installation
	https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular-app-with-ngx-translate
	https://www.codeandweb.com/babeledit	*** Editor che tiene sincronizzati i file multilingua
 */
