import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {NrSortDirection} from './nr-sort-direction.type';
import {NrSortEventInterface} from './nr-sort-event.interface';
import {NrServerError} from '../backend/backend.service';
import {ExportFileResponse, NrTableRequest} from '../backend/naar-api-client';
import * as XLSX from 'xlsx';
import {IUrlService} from '../url-service/url-service.interface';

function getDeep(obj: any, path: string) {
	for (let i = 0, keys = path.split('.'), len = keys.length; i < len; i++) {
		if (obj[keys[i]] === undefined) {
			return undefined;
		}
		obj = obj[keys[i]];
	}
	return obj;
}

export enum eExportSet {
	All,
	Filtered,
	CurrentPage
}

export interface NrSearchResult<T> {
	rows: T[];
	total: number;
}

export interface PageSummary {
	shown: number;
	start: number;
	end: number;
	total: number;
}

interface TableSourceState {
	isPaged: boolean;
	pageNr: number;
	pageSize: number;
	sortPath: string;
	sortDirection: NrSortDirection;
	globalFilter: string;
	pathFilters: { [key: string]: BehaviorSubject<string> };
}

export abstract class TableSource {

	protected state: TableSourceState = null;

	public _empty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public get empty$(): Observable<boolean> {
		return this._empty$.asObservable();
	}

	protected _sortChanged$: BehaviorSubject<NrSortEventInterface> = new BehaviorSubject<NrSortEventInterface>({column: '', direction: ''});
	public get sortChanged$(): Observable<NrSortEventInterface> {
		return this._sortChanged$.asObservable();
	}

	protected _pageSummary$: BehaviorSubject<PageSummary> = new BehaviorSubject<PageSummary>(null);
	public get pageSummary$(): Observable<PageSummary> {
		return this._pageSummary$.asObservable();
	}

	protected _exporting$ = new BehaviorSubject<boolean>(false);
	public get exporting$() {
		return this._exporting$.asObservable();
	}

	public get pageNr(): number {
		return this.state.pageNr;
	}

	public set pageNr(pageNr: number) {
		this.setStateProp({pageNr});
	}

	public get pageSize(): number {
		return this.state.pageSize;
	}

	public set pageSize(pageSize: number) {
		if (pageSize < this.state.pageSize) {
			const maxPage = Math.floor(this.originalDataLength / pageSize) + (this.originalDataLength % pageSize ? 1 : 0);
			if (maxPage < this.pageNr) {
				this.setStateProp({pageNr: maxPage});
			}
		}
		this.setStateProp({pageSize});
	}

	public get sorting(): NrSortEventInterface {
		return {
			column: this.state.sortPath,
			direction: this.state.sortDirection
		};
	}

	public get filters(): { [key: string]: BehaviorSubject<string> } {
		return this.state.pathFilters;
	}

	public abstract get isFiltered(): boolean;

	public abstract get allowExportSubset(): boolean;

	private setStateProp(patch: Partial<TableSourceState>) {
		Object.assign(this.state, patch);
		this.createData();
	}

	public setFilter(path: string, value: string) {
		if (this.state.pathFilters.hasOwnProperty(path)) {
			this.state.pathFilters[path].next(value);
		}
	}

	protected abstract get originalDataLength(): number;

	protected abstract createData();

	public setSorting(sort: NrSortEventInterface) {
		this.state.sortPath = sort.column;
		this.state.sortDirection = sort.direction;
		this.createData();
		this._sortChanged$.next(sort);
	}

	public abstract export(set?: eExportSet): Promise<ExportFileResponse>;
}

export class TableSourceRow<T> {

	private _selected = false;
	public get selected(): boolean {
		return this._selected;
	}

	public set selected(value: boolean) {
		this._selected = value;
		this.tableSource.checkSelected();
	}

	constructor(
		private tableSource: TypedTableSource<T>,
		public data: T) {
	}
}

export abstract class TypedTableSource<T> extends TableSource {

	protected _data$: BehaviorSubject<TableSourceRow<T>[]> = new BehaviorSubject<TableSourceRow<T>[]>([]);
	public get data$(): Observable<TableSourceRow<T>[]> {
		return this._data$.asObservable();
	}

	protected pageData: TableSourceRow<T>[];
	public selected$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

	protected _allSelected = false;
	public get allSelected(): boolean {
		return this._allSelected;
	}

	public set allSelected(value: boolean) {
		this._allSelected = value;
		this.pageData.forEach(d => d.selected = value);
	}

	public checkSelected() {
		const selected = this.pageData.filter(d => d.selected).map(d => d.data);
		this.selected$.next(selected);
		this._allSelected = selected.length === this.pageData.length;
	}
}

export class LocalTableSource<T> extends TypedTableSource<T> {

	protected originalData: T[] = [];
	protected sortedData: T[];
	protected filteredData: T[];

	protected get originalDataLength(): number {
		return this.originalData.length;
	}

	protected get exportSheetName(): string {
		return 'Export';
	}

	protected get exportFileName(): string {
		return 'export.xlsx';
	}

	protected get exportHeaderNames(): string[] {
		return null;
	}

	private _filtered = false;

	public get isFiltered(): boolean {
		return this._filtered;
	}

	public get allowExportSubset(): boolean {
		return true;
	}

	constructor(public isPaged?: boolean, allowedPathFilters?: string[]) {
		super();
		this.isPaged = !!this.isPaged;
		this.state = {
			pathFilters: {},
			globalFilter: null,
			isPaged,
			pageNr: 1,
			pageSize: 25,
			sortPath: null,
			sortDirection: '',
		};
		if (allowedPathFilters) {
			allowedPathFilters.forEach(path => {
				{
					this.state.pathFilters[path] = new BehaviorSubject<string>('');
					this.state.pathFilters[path]
						.pipe(
							debounceTime(200),
							distinctUntilChanged()
						)
						.subscribe(_ => this.createData());
				}
			});
		}
	}

	private sortFunc(a: T, b: T): number {
		if (getDeep(a, this.state.sortPath) === getDeep(b, this.state.sortPath)) {
			return 0;
		}
		return getDeep(a, this.state.sortPath) > getDeep(b, this.state.sortPath) ? (this.state.sortDirection === 'asc' ? 1 : -1) : (this.state.sortDirection === 'asc' ? -1 : 1);
	}

	private createSortedData() {
		const sortedData = this.originalData ? [...this.originalData] : [];
		if (this.state.sortPath && this.state.sortDirection) {
			sortedData.sort(this.sortFunc.bind(this));
		}
		this.sortedData = sortedData;
	}

	private createPageData() {
		if (this.isPaged) {
			const start = (this.state.pageNr - 1) * this.state.pageSize;
			if (start >= this.filteredData.length) {
				this.pageData = [];
			} else {
				this.pageData = this
					.filteredData
					.slice(start, start + this.state.pageSize)
					.map(row => new TableSourceRow<T>(this, row));
			}
		} else {
			this.pageData = [...this.filteredData].map(row => new TableSourceRow<T>(this, row));
		}
	}

	private createFilteredData() {
		const activeFilters = Object
			.keys(this.state.pathFilters)
			.map(k => {
				return {path: k, value: this.state.pathFilters[k].value};
			})
			.filter(f => f.value)
			.map(f => {
				return {path: f.path, value: new RegExp(f.value.trim(), 'i')};
			});
		if (activeFilters.length) {
			this.filteredData = this.sortedData.filter(row => {
				for (const f of activeFilters) {
					if (!f.value.test(String(getDeep(row, f.path) || ''))) {
						return false;
					}
				}
				return true;
			});
		} else {
			this.filteredData = this.sortedData;
		}
		this._filtered = this.filteredData.length !== this.originalData.length;
	}

	protected createData() {
		this.selected$.next([]);
		this.createSortedData();
		this.createFilteredData();
		this.createPageData();
		this._data$.next(this.pageData);
		const start = (this.filteredData.length ? 1 : 0) + (this.pageNr - 1) * this.pageSize;
		const end = start ? start + this.pageData.length - 1 : 0;
		const summary: PageSummary = {
			shown: this.pageData.length,
			start,
			end,
			total: this.filteredData.length
		};
		this._pageSummary$.next(summary);
	}

	public setData(data: T[]) {
		if (this._empty$.value) {
			this._empty$.next(false);
		}
		this.originalData = data || [];
		this.selected$.next([]);
		this._allSelected = false;
		this.createData();
	}

	/**
	 * Ottiene un *riferimento* ai dati originali
	 */
	public getCurrentRawData(): T[] {
		return this.originalData;
	}

	public checkSelected() {
		const selected = this.pageData.filter(d => d.selected).map(d => d.data);
		this.selected$.next(selected);
		this._allSelected = selected.length === this.pageData.length;
	}

	public export(set: eExportSet = eExportSet.All): Promise<ExportFileResponse> {
		const sourceData = set === eExportSet.All ? this.sortedData : (set === eExportSet.Filtered ? this.filteredData : this.pageData.map(row => row.data));
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(sourceData.map(row => this.createExportRow(row)));
		let headers = this.exportHeaderNames;
		if (!headers && sourceData.length) {
			headers = Object.getOwnPropertyNames(sourceData[0]);
		}
		if (headers) {
			ws['!autofilter'] = {ref: XLSX.utils.encode_range({c: 0, r: 0}, {c: headers.length - 1, r: 0})};
			for (let c = 0; c < headers.length; c++) {
				const address = XLSX.utils.encode_cell({c, r: 0});
				ws[address].v = headers[c];
			}
		}
		XLSX.utils.book_append_sheet(wb, ws, this.exportSheetName);
		XLSX.writeFile(wb, this.exportFileName, {cellStyles: true, compression: true, bookVBA: false});
		const response = new ExportFileResponse({file: this.exportFileName});
		return Promise.resolve(response);
	}

	protected createExportRow(row: T): any {
		return row;
	}

	public dispose() {
		for (const k in this.filters) {
			if (this.filters.hasOwnProperty(k)) {
				this.filters[k].complete();
			}
		}
	}

}

export abstract class ServerTableSource<T> extends TypedTableSource<T> {

	public get isFiltered(): boolean {
		return false;
	}

	public get allowExportSubset(): boolean {
		return false;
	}

	private _loading$ = new BehaviorSubject<boolean>(true);
	public get loading$() {
		return this._loading$.asObservable();
	}

	private _search$ = new Subject<void>();
	public error$ = new Subject<Error | NrServerError>();

	protected constructor(protected urlService: IUrlService) {
		super();
		this.state = {
			pathFilters: {},
			globalFilter: null,
			isPaged: true,
			pageNr: 1,
			pageSize: 25,
			sortPath: null,
			sortDirection: '',
		};

		this._search$.pipe(
			tap(() => this._loading$.next(true)),
			debounceTime(200),
			switchMap(() => this._search()),
			delay(200),
			tap(() => this._loading$.next(false))
		).subscribe(result => {
			this.pageData = result.rows.map(r => new TableSourceRow<T>(this, r));
			this._data$.next(this.pageData);
			const start = (result.rows.length ? 1 : 0) + (this.pageNr - 1) * this.pageSize;
			const end = start ? start + this.pageData.length - 1 : 0;
			this._pageSummary$.next({
				shown: result.rows.length,
				start,
				end,
				total: result.total
			});
			if (this._empty$.value) {
				this._empty$.next(false);
			}
		}, err => {
			this._loading$.next(false);
			this.error$.next(err);
		});

		this._search$.next();
	}

	public requery() {
		this.pageNr = 1;
	}

	protected createData() {
		this.selected$.next([]);
		this._allSelected = false;
		this._search$.next();
	}

	protected get originalDataLength(): number {
		return (this._pageSummary$.value?.total) ?? 0;
	}

	public get nrTableSourceState(): Partial<NrTableRequest> {
		return {
			page: this.state.pageNr,
			pageSize: this.state.pageSize,
			sortColumn: this.state.sortPath,
			sortDirection: this.state.sortDirection
		};
	}

	protected abstract _search(): Observable<NrSearchResult<T>>;

	protected abstract _export(): Promise<ExportFileResponse>;

	public async export(): Promise<ExportFileResponse> {
		try {
			this._exporting$.next(true);
			const exportFile = await this._export();
			if (this.urlService) {
				this.urlService.openExportFile(exportFile);
			}
			return exportFile;
		} finally {
			this._exporting$.next(false);
		}
	}
}
