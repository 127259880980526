import {AgencyData, DebugData, ETipoUtente} from '../backend/naar-api-client';

export class User {

	private _displayName: string = null;
	public get displayName(): string {
		if (!this._displayName) {
			this._displayName = `${(this.firstName || '')} ${(this.lastName || '')}`;
		}
		return this._displayName;
	}

	constructor(
		public id: number,
		public ssoToken: string,
		public firstName: string,
		public lastName: string,
		public type: ETipoUtente,
		public agencyData: AgencyData,
		public debugData: DebugData) {
	}
}
